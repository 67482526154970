<template>
    <div>Examle komponenta</div>
</template>

<script>
    export default {
        mounted () {

        }
    }
</script>