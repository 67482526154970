<template>

<b-modal ref="flash" class="nonFooHead">
	<slot name="message"></slot>
	<div slot="modal-footer" class="w-100"></div>			
</b-modal>

</template>
<script>
	
export default {
	mounted() {
		this.$refs.flash.show()
	}
}

</script>