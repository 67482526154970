<template>
  <div>
<!--    <span class="dTo">Dobito: {{ meterValueInkWh }} kWh</span>-->
    <span class="dTo">Čas dobíjení: {{ timeChargingCounter }}</span>
    <span class="dTo">až do {{ stopPlannedTimeString }}</span>
    <b-progress :max="max" height="2rem">
      <b-progress-bar :value="percent">
        <span><strong>{{ percent.toFixed(0) }}%</strong></span>
      </b-progress-bar>
    </b-progress>
  </div>
</template>

<script>
export default {
  props: ['urlData', 'locationData', 'timesData'],
  mounted () {
    this.stopPlannedTime = this.prepareDate(this.timesData.stopPlannedTime);
    this.startTime = this.prepareDate(this.timesData.startTime);
    this.stopPlannedTimeString = this.getStopPlannedTimeString(this.stopPlannedTime);
    this.setPercent();
    //this.chargingInfo();
    /*window.setInterval(() => {
      this.chargingInfo()
    }, 180000);*/
    window.setInterval(() => {
      this.getTimeChargingCounter();
    }, 10);
    window.setInterval(() => {
      this.setPercent();
    }, 60000);
  },
  data() {
    return {
      percent: 0,
      max: 100,
      stopPlannedTime: null,
      stopPlannedTimeString: null,
      meterValueInkWh: null,
      startTime: null,
      timeChargingCounter: null
    };
  },
  methods: {
    chargingInfo() {
        this.$http.get(this.urlData).then((response)=>{
          if (response.status==200 && response.data) {
            this.meterValueInkWh = response.data.meterValueInWh/1000;
          }
          else {
            window.location.href = this.locationData;
          }
        });
    },
    getTimeChargingCounter() {
      var dt = new Date();
      if (dt<=this.stopPlannedTime || this.stopPlannedTime==null) {
        this.timeChargingCounter = this.getTimeCharging(dt.getTime() / 1000 - this.startTime.getTime() / 1000);
      }
    },
    prepareDate(stopPlannedTime) {
      return new Date(Date.parse(stopPlannedTime));
    },
    getStopPlannedTimeString(stopPlannedTime) {
      var month = this.to2char(stopPlannedTime.getMonth()+1);
      var day = this.to2char(stopPlannedTime.getDate());
      var hours = this.to2char(stopPlannedTime.getHours());
      var minutes = this.to2char(stopPlannedTime.getMinutes());
      return day
          +'.'+month
          +'.'+stopPlannedTime.getFullYear()
          +' '+hours
          +':'+minutes
      ;
    },
    getTimeCharging(timeChargingInSec) {
      var totalSeconds = timeChargingInSec;
      var hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      var minutes = Math.floor(totalSeconds / 60);
      var seconds = totalSeconds % 60;
      seconds = seconds.toFixed(0);
      return this.to2char(hours)+':'+this.to2char(minutes)+':'+this.to2char(seconds);
    },
    to2char(s){
      s = ''+s;
      return s.length===1?'0'+s:s;
    },
    setPercent() {
      var dt = new Date();
      this.percent = 0;
      if (dt<=this.stopPlannedTime || this.stopPlannedTime==null) {
        this.percent = 100-(100*(this.stopPlannedTime.getTime() - dt.getTime())/(this.stopPlannedTime.getTime() - this.startTime.getTime()));
      }
    }
  }
}
</script>