var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", { staticClass: "dTo" }, [
        _vm._v("Čas dobíjení: " + _vm._s(_vm.timeChargingCounter))
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "dTo" }, [
        _vm._v("až do " + _vm._s(_vm.stopPlannedTimeString))
      ]),
      _vm._v(" "),
      _c(
        "b-progress",
        { attrs: { max: _vm.max, height: "2rem" } },
        [
          _c("b-progress-bar", { attrs: { value: _vm.percent } }, [
            _c("span", [
              _c("strong", [_vm._v(_vm._s(_vm.percent.toFixed(0)) + "%")])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }