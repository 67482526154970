<template></template>
<script>
	
export default {
	props: ['urlData', 'locationData'],
	mounted() {
		this.$http.get(this.urlData).then((response)=>{
			if (response.status===200 && response.data.on===true) {
				window.location.href = this.locationData;
			}
		});
	}
  /*async mounted() {
    try {
      const response = await this.$http.get(this.urlData);
      console.log(response.status, response.data, response);
      if (response.status === 200 && response.data === 1) {
        window.location.href = this.locationData;
      }
    } catch (error) {
      console.error('Chyba při získávání dat:', error);
    }
  }*/
}

</script>