var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    { ref: "flash", staticClass: "nonFooHead" },
    [
      _vm._t("message"),
      _vm._v(" "),
      _c("div", {
        staticClass: "w-100",
        attrs: { slot: "modal-footer" },
        slot: "modal-footer"
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }