import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'

import ExampleComponent from './components/ExampleComponent';
import FlashModalComponent from './components/modals/FlashModalComponent';
import CalllComponent from './components/ajax/CalllComponent';
import ChargingInfo from './components/charging-info/ChargingInfo';
import CookieLaw from 'vue-cookie-law';
//import "babel-polyfill";

import Axios from 'axios'
import VueAxios from 'vue-axios'

window.Vue = Vue;

var axios = Axios.create();
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.component(ExampleComponent);
Vue.component('cookie-law', CookieLaw);
Vue.component('v-flash',FlashModalComponent);
Vue.component('v-ajax',CalllComponent);
Vue.component('v-charging-info',ChargingInfo);

new Vue({
    el: '#app-wrapper',
		data: {
			errors: [],
			agree: null,
			tarif: null,
			email: null,
			socket: null
		},		
		methods:{
			handleButtonClick: function () {
				if (this.$refs && this.$refs.email && this.$refs.email.id) {
					this.email = document.getElementById(this.$refs.email.id).getAttribute('value');
				}
			},
			checkForm: function (e) {
				if (this.agree && this.tarif && this.email) {
					return true;
				}
				this.errors = [];

				if (!this.agree) {
					this.errors.push('Musíte souhlasit s podmínkami.');
				}
				if (!this.tarif) {
					this.errors.push('Vyberte prosím tarif.');
				}
				if (!this.email) {
					this.errors.push('Vyplňte váš e-mail.');
				} else if (!this.validEmail(this.email)) {
					this.errors.push('E-mail není ve správném formátu.');
				}
				e.preventDefault();
			},
			checkFormE: function (e) {
				this.errors = [];
				
				if (!this.email) {
					this.errors.push('Vyplňte váš e-mail.');
				} else if (!this.validEmail(this.email)) {
					this.errors.push('E-mail není ve správném formátu.');
				}	
				
				if (!this.errors.length) {
					return true;
				}					

				e.preventDefault();
			},
			checkFormSocket: function (e) {
				if (this.socket) {
					return true;
				}
				this.errors = [];

				if (!this.socket) {
					this.errors.push('Vyberte prosím dobíjecí bod.');
				}

				e.preventDefault();
			},
			validEmail: function (email) {
				var re = /^[^.@ ]+(\.[^.@ ]+)*@([^.@ ]+[.])+[a-zA-Z]{2,10}$/;
				return re.test(email);
			}
		}
});

require('../images/nabijeci-stanice.png');
require('../images/logo-pre.png');
require('../images/ico-zasuvka-1w.png');
require('../images/ico-zasuvka-2w.png');
require('../images/ico-zasuvka-3w.png');
require('../images/ico-zasuvka-4w.png');
require('../images/ico-mapa.png');
require('../images/ico-card.png');
require('../images/ico-pdf.png');
require('../images/logo-prahanabiji.png');
require('../images/zastrcka.svg');